<template>
  <section class="hero is-primary is-fullheight login-principal-container">
    <!-- Hero head: will stick at the top -->
    <div class="hero-head">
      <nav class="navbar">
        <div class="container">
          <div class="navbar-brand">
            <a class="navbar-item" href="https://www.coconutcontrol.com/">
              <img src="@/assets/img/logo-final.png" />
            </a>
            <span class="navbar-burger burger" data-target="navbarMenuHeroA">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
          <div id="navbarMenuHeroA" class="navbar-menu">
            <div class="navbar-end">
              <a class="navbar-item" @click="logout()">
                <b class="change-login-register-action">Cerrar sesión </b>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>

    <!-- Hero content: will be in the middle -->
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="columns is-centered absolut-center">
          <div class="column is-half login-form-container">
            <p class="title" data-testid="login-title">
              Antes de contiuar es necesario que verifiques tu correo
            </p>
          </div>
          <div class="column is-half payment-message-container">
            Hemos enviado un mensaje de verificación tu correo, antes de
            continuar es necesario que verifiques tu correo, si no recibiste un
            mensaje de verificación haz click en el botón de abajo
          </div>
          <div class="column is-half payment-button">
            <b-button class="payment-button" @click="sendNewValidationMessage()"
              >Reenviar mensaje de verificación</b-button
            >
          </div>
        </div>
      </div>
    </div>

    <!-- Hero footer: will stick at the bottom -->
    <div class="hero-foot">
      <nav class="tabs">
        <div class="container">
          <ul>
            <li class="is-active"><a></a></li>
            <li><a>Punto de venta</a></li>
            <li><a>Control de inventario</a></li>
            <li><a>Venta de productos</a></li>
            <li><a>Administración de sucursales</a></li>
            <li><a>Control de pedidos</a></li>
          </ul>
        </div>
      </nav>
    </div>
  </section>
</template>

<script>
import { checkValidationErrors, validateFields } from "../../../utils/fns";
// @ is an alias to /src
export default {
  name: "EmailNotValidated",
  components: {},
  data() {
    return {};
  },
  mounted() {
    if (this.userInformation.validatedEmail) {
      this.$router.push("/Dashboards/Initial");
    }
  },
  methods: {
    openLogin() {
      this.$router.push({ path: "/Login" });
    },
    async sendNewValidationMessage() {
      const response = await this.$store.dispatch("RESENDVALIDATIONEMAIL", {
        userEmail: this.userInformation.email,
      });
      if (!response === "Success") {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al enviar correo de validacion",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
        return;
      }
      this.$buefy.dialog.confirm({
        title: "Éxito",
        message: "Se envio exitosamente el correo de validación",
        confirmText: "Entendido",
        type: "is-success",
        hasIcon: true,
        onConfirm: () => {},
        cancelText: "cancelar",
        canCancel: false,
      });
    },
    async logout() {
      this.$store.dispatch("LOGOUT_DATABASE");
      this.$store.dispatch("LOGOUT");
      this.$router.push({ path: "/Login" }).catch(() => {});
    },
  },
  watch: {
    userInformation() {
      if (this.userInformation.validatedEmail) {
        this.$router.push("/Dashboards/Initial");
      }
    },
  },
  computed: {
    userInformation() {
      return this.$store.getters.USER;
    },
  },
};
</script>

<style scoped>
@import "../style/Login.css";
@import "../style/Global.css";
</style>
